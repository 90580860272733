import * as actionTypes from '../actions';

// Define initialState
const initialState = {
    selectedTemplate:{},
    allTemplates: [],
    tempId:undefined,
    selectedDay:1,
    selectedType:"exercise",
    exercisesMealsPerDay:{
        exercises:[],
        meals:[],
        id:undefined,
        break_day_exercise:false,
        break_day_meal:false,
        break_day:false
    },
    selectedExercises:[],
    selectedExercise:undefined,


    /////
    openDaysModalId:undefined,
    openAddExerciseModal:false,
    openAddExerciseSecondModal:false,
    muscleExercises:[],
    showFinalModal: false,
    selectedMuscleExercise:undefined,
    modalDetailed:false,
    modalEdited:false,
    loaded:false,
    load:false,
    openDelete:false,
    itemForDelete:{},
    openBreak:false,
    
    //// Meal ///
    openAddMealModal:false,
    openMealDetails:false,
    mealDataItem:{},
    openFoodModal:false,
    foodItem:{},
    addFoodModal:false,
    isEdited:false,
    fixedMuscleExercises:[]
}

// Define the Reducer
export default function template_reducer(state = initialState, action) {
    switch (action.type) {

        case actionTypes.Set_Selected_Template:
            return {
                ...state,
                selectedTemplate: action.temp,
                tempId:action.tempId,
                openAddExerciseModal: false,
                openAddExerciseSecondModal:false
            }

        case actionTypes.Set_Load:
            return {
                ...state,
                load:action.load
            }
        
        case actionTypes.Set_All_Templates:
            return {
                ...state,
                allTemplates: action.templates
            }

        case actionTypes.Set_Selected_Day:
            return{
                ...state,
                selectedDay:action.day
            }
        case actionTypes.Get_Exercises_Meals_Per_Day:
            return{
                ...state,
                exercisesMealsPerDay:action.data
            }
        case actionTypes.Set_Selected_Type:
            return {
                ...state,
                selectedType:action.activeType,
                openAddExerciseModal: false,
                openAddExerciseSecondModal:false
            }
        case actionTypes.Set_Selected_Exercises:
            return{
                ...state,
                selectedExercises:action.selectedExercises,
                selectedExercise : action.selectedExercise
            }
        case actionTypes.Set_Open_Days_Modal:
            return {
                ...state,
                openDaysModalId:action.openDaysModalId
            }
        case actionTypes.Open_Add_Exercise_modal:
            return {
                ...state,
                openAddExerciseModal: action.openFirst
            }
        case actionTypes.Open_Add_Exercise_Second_modal:
            return {
                ...state,
                openAddExerciseSecondModal:action.openSecond
            }
        case actionTypes.Fetch_Muscle_Exercises:
            return {
                ...state,
                muscleExercises:action.data
            }
        case actionTypes.Fetch_Fixed_Muscle_Exercises:{
            return {
                ...state,
                fixedMuscleExercises:action.data
            }
        }
        case actionTypes.Show_Final_Modal : 
        return {
            ...state,
            showFinalModal : action.showFinal,
        }

        case actionTypes.Set_Selected_Muscle_Exercise:
            return {
                ...state,
                selectedMuscleExercise: action.selectedMuscleExercise
            }
        case actionTypes.Set_Modal_Detailed:
            return{
                ...state,
                modalDetailed:action.detailed
            }
        case actionTypes.Set_Modal_Edited:
            return{
                ...state,
                modalEdited:action.modalEdited
            }

        case actionTypes.Set_Loaded: 
            return {
                ...state,
                loaded:action.loaded
            }

        ////////////////
        case actionTypes.Open_Add_Meal_Modal:
            return {
                ...state,
                openAddMealModal : action.open
            }

        case actionTypes.Open_Meal_Details:
            return {
                ...state,
                openMealDetails: action.openDetails
            }

        case actionTypes.Set_Meal_Detail:
            return {
                ...state,
                mealDataItem:action.item
            }
        case actionTypes.Open_Food_Modal:
            return {
                ...state,
                openFoodModal:action.foodModal
            }

         case actionTypes.Show_Add_Food_Modal:
            return {
                ...state,
                addFoodModal:action.addFoodModal
            }

         case actionTypes.Set_Food_Item:
            return {
                ...state,
                foodItem:action.foodItem
            }
        case actionTypes.Open_Food_Modal_Edited:
            return {
                ...state,
                isEdited : action.isEdit
            }
        case actionTypes.Delete_Item:
            return {
                ...state,
                itemForDelete:action.item
            }
        case actionTypes.Set_Open_Delete:
            return {
                ...state,
                openDelete:action.openDelete
            }
        case actionTypes.Set_Open_Break:
            return {
                ...state,
                openBreak:action.openBreak
        }

        case actionTypes.Clear:
            return initialState

        default:
            return state;
    }
};
