import { memo } from 'react';
import {withTranslation} from "react-i18next";

function FooterComponent(props){
    const isArabic = props.i18n.language === 'ar';
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="text-center mt-3">
                        {isArabic ? 'جميع الحقوق محفوظة ' : 'All rights reserved '}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation("translation") (memo(FooterComponent));