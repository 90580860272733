import config from "../../config"

const base_url = `${config.url}/api`
// const base_url = ''
// const base_url = 'https://.miranapp.com/api'


 const post = async ({ target, body , isLogin = false }) => {
    const token = localStorage.getItem('miran-token')
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'App-Version' : '2.1.6'
    }
    const authorizationHeader = {
        'Authorization': token ? `token ${token}` : null
    }
    if(!isLogin){
        headers = {...headers , ...authorizationHeader}
    }
    
    debugger
    try {
        const url = `${base_url}/${target}`;
        const result = await fetch(url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body)
        })
        return await result.json()

    } catch (error) {
        debugger
        console.log('error', error)
    }

}
const patch = async ({ target, body  }) => {
    const token = localStorage.getItem('miran-token')
    try {
        const url = `${base_url}/${target}`;
        const result = await fetch(url, {
            method: "PATCH",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token ? `token ${token}` : null,
                'App-Version' : '2.1.6',
                'Access-Control-Allow-Methods': 'GET, PUT, PATCH, POST, DELETE, HEAD, OPTIONS'
            },
            body: JSON.stringify(body)
        })
        return await result.json()

    } catch (error) {
        console.log('error', error)
    }

}

const get = async ({target , comingToken}) => {
    const token = localStorage.getItem('miran-token')
    console.log("tok", localStorage.getItem('miran-token'))
    const url = `${base_url}/${target}`
    const h = {'Authorization': comingToken ? `ciis ${comingToken}` : token ? `token ${token}` : null} 
    // if(comingToken){
    //     debugger
    // }
    try {
        const result = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'App-Version' : '2.1.6',
                'Authorization': comingToken ? `token ${comingToken}` : token ? `token ${token}` : null,
            },
        })

        return await result.json()
    } catch (error) {
        // debugger
        console.log('error', error)
    }
}


const put = async({ target, body }) => {
    try {
        const url = `${base_url}/${target}`;
        const token = localStorage.getItem('miran-token')
        const result = await fetch(url, {
            method: "PUT",
            headers: {
                // Accept: 'application/json',
                'Content-Type': 'application/json',
                'App-Version' : '2.1.14',
                'Authorization': token ? `token ${token}` : null,
            },
            body: JSON.stringify(body)
        })
        return await result.json()
        debugger;

    } catch (error) {
        console.log('error', error)
    }
}

const  _delete = async ({ target,body }) => {
    try {
        const url = `${base_url}/${target}`;
        const token = localStorage.getItem('miran-token')
        const result = await fetch(url, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token ? `token ${token}` : null,
                'App-Version' : '2.1.14',
            },
            body: JSON.stringify(body)
        })
        return await result.json()
    } catch (error) {
        console.log('error', error)
    }
}



const Post_As_Form_Data = async({target , body}) => {
    const url = `${base_url}/${target}`;
    const token = localStorage.getItem('miran-token');
    console.log("url =>", url)
    try {
        const result = await fetch(url, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'App-Version' : '2.1.6',
                'Authorization': token ? `token ${token}` : null,
            },
            body
        })
        console.log("RE", result)
        return await result.json()
    }catch (error) {
        console.log("error", error)
    }
}

const Put_As_Form_Data = async({target , body}) => {
    const url = `${base_url}/${target}`;
    const token = localStorage.getItem('miran-token');
    console.log("url =>", url)
    try {
        const result = await fetch(url, {
            method: "PUT",
            headers: {
                Accept: 'application/json',
                'App-Version' : '2.1.6',
                'Authorization': token ? `token ${token}` : null,
            },
            body
        })
        console.log("RE", result)
        return await result.json()
    }catch (error) {
        console.log("error", error)
    }
}


export  const HTTP_REQUEST = {
    post,
    patch,
    get,
    put,
    _delete,
    Post_As_Form_Data,
    Put_As_Form_Data
}