import moment from 'moment';
import * as actionTypes from '../actions';

// Define initialState
const initialState = {
    planName: "", 
    planId: undefined, 
    traineesId: undefined, 
    fullData: [], 
    subscription: {},
    exLoading:false, 
    activeDay: moment().format('YYYY-MM-D'),
    exercisesMealsPerDay:{
        exercises:[],
        meals:[],
        id:undefined,
        break_day_exercise:false,
        break_day_meal:false,
        break_day:false
    },
    loaded:false,
    subscriptionData:{},
    calendarDays:[],
    calendarReturned: [],
    activeIndex:0
}

// Define the Reducer
export default function plan_reducer(state = initialState, action) {
    switch (action.type) {

        case actionTypes.Get_Plan_Data:
            return {
                ...state,
                planName:action.data.planName, 
                planId: action.data.planId, 
                traineesId: action.data.traineesId, 
                fullData: action.data.fullData, 
                subscription: action.data.subscription, 
                activeDay:action.data.subscription.start_date
            }

        case actionTypes.Set_Loading:
            return {
                ...state,
                exLoading:action.loading
            }

        case actionTypes.Set_Subscription:
            return {
                ...state,
                subscription : action.sub
            }
        case actionTypes.Get_Exercises_Meals_Plan_Per_Day:
            return{
                ...state,
                exercisesMealsPerDay:action.data
            }
       
        case actionTypes.Set_Selected:
            return {
                ...state,
                planName:action.planName, 
                planId: action.planId, 
                traineesId: action.traineesId, 
                subscription: action.subscription, 
                activeDay:action.activeDay
            }

        case actionTypes.Set_Subscription_Data:
            return {
                ...state,
                subscriptionData:action.data
            }
        case actionTypes.Set_Calendar_Days: 
            return {
                ...state,
                calendarDays: action.days,
            }
        case actionTypes.Set_Calendar_Returned:
            return {
                ...state,
                calendarReturned: action.daysView
            }

        case actionTypes.Set_Active_Date:
            return {
                ...state,
                activeDay: action.day
            }
        case actionTypes.Set_Active_index: 
            return {
                ...state,
                activeIndex:action.index
            }
        default:
            return state;
    }
};
