import * as actionTypes from '../actions';

// Define initialState
const initialState = {
    profileAvatar:"",
    profileData:{
        profile:{
            
        }
    }
}

// Define the Reducer
export default function profile_reducer(state = initialState, action) {
    switch (action.type) {

        case actionTypes.Change_Profile_Avatar:
            return {
                ...state,
                profileAvatar: action.avatar
            }
        case actionTypes.Set_Profile:
            return {
                ...state,
                profileData:action.data
            }
            
        default:
            return state;
    }
};
