import * as actionTypes from '../actions';


// Define initialState
const initialState = {
    allRequests:[],
    trainees:[],
    fixedTrainees:[],
    invitedTrainees:[],
    fixedInvitedTrainees:[],
    templates:[],
    fixedTemplates:[],
    load:false,
    isHovered:false,
    generalLoader:false,
    chated:false,
    logined:false,
    token:null,
    toastMsg : {},
    found:false
}

// Define the Reducer
export default function general_reducer(state = initialState, action) {
    switch (action.type) {

        case actionTypes.Get_Requests:
            return {
                ...state,
                allRequests:action.data
            }
        case actionTypes.Get_Trainees:
            return {
                ...state,
                trainees:action.data,
            }
        case actionTypes.Get_Fixed_Trainees:
            return{
                ...state,
                fixedTrainees:action.data
            }
        case actionTypes.Get_Invited_Trainees:
            return {
                ...state,
                invitedTrainees:action.data
            }
        case actionTypes.Get_Fixed_Invited_Trainees:
            return {
                ...state,
                fixedInvitedTrainees:action.data
            }
        case actionTypes.Get_All_Templates:
            return {
                ...state,
                templates:action.data
            }
        case actionTypes.Get_Fixed_Templates:
            return {
                ...state,
                fixedTemplates:action.data
            }
        case actionTypes.Is_Temp_Loading:
            return {
                ...state,
                tempLoading: action.load
            }
        case actionTypes.Set_Is_Hovered:
            return {
                ...state,
                isHovered:action.hovered
            }

        case actionTypes.Set_General_Loader:
            return{
                ...state,
                generalLoader:action.loading
            }

        case actionTypes.Set_Chated:
            return {
                ...state,
                chated:true
            }
        case actionTypes.Set_Logined:
            return {
                ...state,
                logined:true
            }
        case actionTypes.Set_Token:
            return {
                ...state,
                token: action.token
            }
        case actionTypes.Set_Toast_Msg:
            return {
                ...state,
                toastMsg:action.msg
            }
        case actionTypes.Set_Msg_Found:
            return {
                ...state,
                found: action.found
            }
        default:
            return state;
    }
};