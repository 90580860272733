import React , { lazy , Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App1 from './App1';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {I18nextProvider} from "react-i18next";
import { Provider } from 'react-redux';
import store from './redux/store';
import i18n from './i18n';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Loader } from 'semantic-ui-react';
// import LoginComponent from'./pages/login/login.component'
// import RegisterComponent from'./pages/register/register.component'
// import ForgetPasswordComponent from'./pages/forget-password/forget-password.component'
// import HomeComponent from'./pages/home/home.component'
// import ProfileComponent from'./pages/profile/profile'
// import SettingComponent from'./pages/setting/setting.component'
// import TemplateComponent from'./pages/template/template.component'
// import ConfirmAlertComponent from'./pages/confirm-alert/confirm-alert.component'
// import TemplateDetailsComponent from'./pages/template/template-details.component'
// import ProgressComponent from'./pages/progress/progress.component'
// import FaqComponent from'./pages/faq/faq.component'
// import PlanComponent from'./pages/plan/plan'
// import DisplayTraineesComponent from'./pages/display-trainees/display-trainees.component'
// // import SendInvitationComponent from'./pages/send-invitation/send-invitation.component'

import {
    CometChatUI,
    CometChatUserListWithMessages
} from "./pages/group-chat/CometChatWorkspace/src";
import {
    CometChatMessages
}
    from "./pages/group-chat/CometChatWorkspace/src/components/Messages/CometChatMessages";
import ErrorBoundary from './components/Errors/ErrorBoundary/ErrorBoundary';
const LoginComponent = lazy(() => import('./pages/login/login'))
const RegisterComponent = lazy(() => import('./pages/register/register.component'))
const ForgetPasswordComponent = lazy(() => import('./pages/forget-password/forget-password.component'))
const HomeComponent = lazy(() => import('./pages/home/home'))
const ProfileComponent = lazy(() => import('./pages/profile/profile'))
const SettingComponent = lazy(() => import('./pages/setting/setting'))
const TemplateComponent = lazy(() => import('./pages/template/template'))
const ConfirmAlertComponent = lazy(() => import('./pages/confirm-alert/confirm-alert.component'))
const TemplateDetailsComponent = lazy(() => import('./pages/template/details/NewTemplateDetail'))
const ProgressComponent = lazy(() => import('./pages/progress/progress.component'))
const FaqComponent = lazy(() => import('./pages/faq/faq.component'))
const PlanComponent = lazy(() => import('./pages/plan/NewPlan'))
const DisplayTraineesComponent = lazy(() => import('./pages/display-trainees/displayTrainees'))
const SendInvitationComponent = lazy(() => import('./pages/send-invitation/send-invitation.component'))

const GeneralLoader = () => {
    return (
        <div style={{
            height:"100vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
        }}>
            <Loader inline="centered" active />
        </div>
    )
}

Sentry.init({
        dsn: "https://7520bb7e2bad4e76a1eb301c1052b239@o1100174.ingest.sentry.io/6125145",
        integrations: [new Integrations.BrowserTracing()],
      
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <ErrorBoundary>
        <I18nextProvider i18n={i18n}>
            <Router>
                <Suspense fallback={<GeneralLoader />}>
                    <Switch>
                        <Route path="/login" component={() => <LoginComponent className="login-Component" />} />
                        <Route path="/register" component={() => <RegisterComponent className="register-Component" />} />
                        <Route path="/forget-pw" component={() => <ForgetPasswordComponent className="forgetPw-Component" />} />
                        <Route  path="/confirm-alert" component={() => <ConfirmAlertComponent />} />
                        <App1 >
                            <Route path="/" exact component={HomeComponent} />
                            <Route path="/profile"  component={ProfileComponent} />
                            <Route path="/setting"  component={SettingComponent} />
                            <Route path="/template"  component={TemplateComponent} />
                            <Route path="/template-details"  component={TemplateDetailsComponent} />
                            <Route path="/progress"  component={ProgressComponent} />
                            <Route path="/plan"  component={PlanComponent} />
                            <Route path="/faq"  component={FaqComponent} />
                            <Route path="/trainees"  component={DisplayTraineesComponent} />
                            <Route path="/chat-message" component={CometChatMessages} />

                            <Route path="/users" component={CometChatUserListWithMessages} />
                            <Route path="/chat-app" component={CometChatUI} />
                            {/*<Route path="/chat2" component={LoginChatComponent} />*/}
                        </App1>
                    </Switch>
                </Suspense>
            </Router>

            </I18nextProvider>
            </ErrorBoundary>
        </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
