import BaseService from "../base-service/base.service";
import {HTTP_REQUEST} from "../http-service/httpService";

class UserService extends BaseService {
    constructor() {
        super('user');
    }
    get imageDefaultPath () {
        return "https://backend.miranapp.com/media/";
    }
    get profile () {
        const url = this._endPoint + "/trainer-profile";
        return HTTP_REQUEST.get({target:url});
    }
    updateProfile = async (data) => {
        if(!data)
            return ;
        const url =   this._endPoint + "/trainer-profile";
        return  HTTP_REQUEST.Put_As_Form_Data({target:url , body : data});
    }

    get faq () {
        const url = this._endPoint + "/faq";
        return HTTP_REQUEST.get({target:url});
    }
    addFaq = async (data) => {
        if(!data)
            return ;
        const url =   this._endPoint + "/faq";
        return  HTTP_REQUEST.post({target:url , body : data});
    }

    editFaq = async (id , data) => {
        if(!data)
            return ;
            console.log("jf", id , data)
        const url =   this._endPoint + `/faq/${id}/`;
        return  HTTP_REQUEST.put({target:url , body : data});
    }


    deleteFaq = async (id) => {
       
        const url =   this._endPoint + `/faq/${id}`;
        return  HTTP_REQUEST._delete({target:url});
    }

    // editNote = async (id , data) => {
    //     if(!data)
    //         return ;
    //         console.log("jf", id , data)
    //     const url =   this._endPoint + `/faq/${id}/`;
    //     return  HTTP_REQUEST.put({target:url , body : data});
    // }
    

    // deleteNote = async (id) => {
       
    //     const url =   this._endPoint + `/faq/${id}`;
    //     return  HTTP_REQUEST._delete({target:url});
    // }   

    get traineeList () {
        const url = this._endPoint + "/trainer/students";
        return HTTP_REQUEST.get({target:url});
    }


}

export default UserService;