import React, { useEffect, useState } from "react";
import './App.css';
import  { Redirect , useLocation } from 'react-router-dom'
import AccountService from "./services/account-service/account.service";
import {CometChat} from "@cometchat-pro/chat";
import config from "../src/config";

import SideBar from './components/common/side-bar/sideBar'
import FooterComponent from './components/common/footer/footer.component'
import HeaderComponent from './components/common/header/Header'
import TraineeService from "./services/trainee-service/traniee.service";
import { ChatService } from "./pages/group-chat/service/chat.service";
import UserService from './services/user-service/user.service';
import { useDispatch, useSelector } from "react-redux";
import { useCheckConversations } from "./useCheckConversation";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const list = [
    {
        chat_uid: "15757",
        email: "omar+t81145559@miranapp.com",
        full_name: "Ahmed Elghamdi",
        goal: {id: 1, title: 'Weight Loss', title_ar: 'إنقاص الوزن'},
        id: "132474",
        profile: {id: 155013, avatar:"avatar/202673/bg-01.jpg", full_name: 'omar+t81145559', email: 'omar+t81145559@miranapp.com', birthdate: '1990-01-01'},
        subscribtion_end: 25
    },
    {
        chat_uid: "202673",
        email: "omar+t81145559@miranapp.com",
        full_name: "Ahmed Noah",
        goal: {id: 1, title: 'Weight Loss', title_ar: 'إنقاص الوزن'},
        id: "132474",
        profile: {id: 155013, avatar:"avatar/202673/bg-01.jpg", full_name: 'omar+t81145559', email: 'omar+t81145559@miranapp.com', birthdate: '1990-01-01'},
        subscribtion_end: 25
    },
    {
        chat_uid: "15777",
        email: "omar+t81145559@miranapp.com",
        full_name: "Ahmed",
        goal: {id: 1, title: 'Weight Loss', title_ar: 'إنقاص الوزن'},
        id: "132474",
        profile: {id: 155013, avatar:"avatar/202673/bg-01.jpg", full_name: 'omar+t81145559', email: 'omar+t81145559@miranapp.com', birthdate: '1990-01-01'},
        subscribtion_end: 25
    },
    // {
    //     chat_uid: "258966_t",
    //     email: "omar+t81145559@miranapp.com",
    //     full_name: "مصطفي",
    //     goal: {id: 1, title: 'Weight Loss', title_ar: 'إنقاص الوزن'},
    //     id: "258966",
    //     profile: {id: 155013, avatar:"avatar/202673/bg-01.jpg", full_name: 'omar+t81145559', email: 'omar+t81145559@miranapp.com', birthdate: '1990-01-01'},
    //     subscribtion_end: 25
    // }
]

const App1 = props => {
    let conversationListenerId = "chatlist_" + new Date().getTime();
    const [mounted , setMounted] = useState(false)
    const [state, setState] = useState({
            isAuthorize : false,
            hasHorizontalScrollbar : false,
            hasVerticalScrollbar:false,
            isActive:false
        })
        const [showRequestBadge , setShowRequestBadge] = useState(false)
        const accountService = new AccountService();
        const _authorize = accountService.isAuthorize();
        const chatService = new ChatService();
        const traineeService = new TraineeService()
        const userService = new UserService()
        const dispatch = useDispatch()
        const { t } = props
        const { trainees, isHovered, generalLoader , allRequests, chated , logined } = useSelector(state => state.general_reducer)
        // CometChat.init(config.appId).then(r => console.log('init'));
        const location = useLocation() 
        useCheckConversations()

    useEffect(() => {
        setShowRequestBadge(allRequests.length > 0)
    } , [allRequests])

    useEffect(() => {
        const isActive = JSON.parse(accountService.isAccountActivated());
        const userData = JSON.parse(accountService.userData);
        setState({
            ...state,
            isAuthorize : _authorize,
            isActive
         })
         setMounted(true)
        //  debugger
        if(userData !== null ){
            dispatch({
                type: "Set_Token",
                token:userData.token
            })
        }
        // debugger
    } , [])

    const checkStorage = () => {
        try {
            // const flag = 'localStorage' in window && window.localStorage !== null
            localStorage.setItem("test", "1")
            localStorage.removeItem("test", "1")
            return true
            // if ('localStorage' in window && window.localStorage !== null) {
            // }
            debugger
         } catch (_) {
             debugger
             return false
         }
    }

    useEffect(() => {
       const flag = checkStorage()
       if(!flag){
           alert("يجب تفعيل  cookies في المتصفح لكي تبقي متصل")
       }
       debugger
    } , [])
    
    const fetchTrainees = async () => {
        traineeService.trainees.then(data => {
            // debugger
            if(data && data.result){
                dispatch({
                    type:"Get_Trainees",
                    data: data.result
                })
                dispatch({
                    type:"Get_Fixed_Trainees",
                    data: data.result
                })
                localStorage.setItem('trainees', JSON.stringify(data.result));
            }
        })
    }
    useEffect(() => {
        fetchTrainees()
        // debugger
    } , [])

    const getUsers = async () => {
        trainees.forEach(async item => {
            // debugger;
            await CometChat.getUser(item.chat_uid).then(userFetched => {
                // debugger;
                console.log("userFetched", userFetched)
                }).catch(async err => {
                    // debugger;
                    await chatService.addNewUser({uid:`${item.chat_uid}` , name:item.full_name})
                })
        })   
    }
    useEffect(() => {
        // debugger
        if(logined && !chated){
            if(trainees.length > 0){
                // debugger
                getUsers()
            }
        }
    } , [logined , chated , trainees.length])

    const chatLoginHandler = async () => {
        const userData = JSON.parse(accountService.userData);
        // debugger
        if(userData !== null ){
        //    debugger
                await CometChat.login(userData.chat_uid ,config.apiKey).then(
                    User => {
                        // debugger
                        dispatch({
                            type:"Set_Logined"
                        })
                        console.log("Login Successful:", { User });
                        // User loged in successfully.
                    },
                    error => {
                        console.log("Login failed with exception:", { error });
                        debugger
                        if(error ){
                        debugger
                                const user = new CometChat.User({uid:userData.chat_uid, name:userData.full_name  , metadata : userData});
                                debugger 
                                CometChat.createUser(user,config.apiKey).then(user => {
                                    console.log("user =>", user)
                                    debugger
                                    CometChat.login(user.uid, config.apiKey).then(
                                        User => {
                                            dispatch({
                                                type:"Set_Logined"
                                            })
                                            console.log("Login Successful:", { User });
                                            debugger
                                            // User loged in successfully.
                                        },
                                    ).catch(e => {
                                        debugger
                                    })
                                }).catch(err => {
                                    console.log("err =>", err)
                                    debugger
                                    CometChat.login(user.uid, config.apiKey).then(
                                        User => {
                                            dispatch({
                                                type:"Set_Logined"
                                            })
                                            console.log("Login Successful:", { User });
                                            debugger
                                            // User loged in successfully.
                                        },
                                    ).catch(e => {
                                        debugger
                                    })
                                })
                            }
                        }
                        // User login failed, check error and take appropriate action.
                );
               }
    }

    useEffect(() => {
        // debugger;
        const appID = config.appId;
        const region = "eu";
        const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
        CometChat.init(appID, appSetting).then(
        () => {
            console.log("Initialization completed successfully");
            console.log("trine", trainees)
            chatLoginHandler()
            // debugger;
            // You can now call login function.
        },
        error => {
            console.log("Initialization failed with error:", error);
            // Check the reason for error and take appropriate action.
        });
        
    } , [])

    useEffect(() => {
        // debugger
        userService.profile.then(data => {
            // debugger
            if(data && data.result && data.result.profile){
                dispatch({
                    type:"Set_Profile",
                    data:data.result
                })
            }
        })
    },[])

    const displayScrollbar  = () => {
        const pusherElement = document.getElementsByClassName('pusher')[0];
        if(pusherElement) {
            const hasHorizontalScrollbar = pusherElement.scrollWidth > pusherElement.clientWidth;
            const hasVerticalScrollbar = pusherElement.scrollHeight > pusherElement.clientHeight;
            setState({
                ...state,
                hasHorizontalScrollbar,
                hasVerticalScrollbar
            })
        }

    }

    useEffect(() => {
        // debugger
        CometChat.addMessageListener(
            conversationListenerId,
            new CometChat.MessageListener({
                onTextMessageReceived: textMessage => {
                    dispatch({
                        type: "Set_Toast_Msg",
                        msg: textMessage,
                    })
                    dispatch({
                        type : "Set_Msg_Found",
                        found:true
                    })
                    // toast.success(`${textMessage.sender.name} sent You New Msg (${textMessage.text})`)
                    debugger
                    if(trainees.length > 0){
                        const newTrainees = [...trainees]
                        const updatedTraineeIndex = trainees.findIndex(item => item.chat_uid === textMessage.sender.uid)
                        const updatedTrainee = trainees.find(item => item.chat_uid === textMessage.sender.uid)
                        newTrainees[updatedTraineeIndex] = {...updatedTrainee , newMessageReceived:1}
                        // debugger
                        if(updatedTrainee){
                            // debugger
                            dispatch({
                                type:"Set_Chated",
                            })
                            dispatch({
                                type:"Get_Trainees",
                                data:newTrainees
                            })
                        }
                    }

                    // callback(enums.TEXT_MESSAGE_RECEIVED, null, textMessage);
                },
            }))
    },[logined , trainees.length])


    if(mounted){
        if(!state.isAuthorize) {
            return <Redirect to={'/login?notAuth=true'} />
        }else{
            return (
                <div> 
                    <div className="row">
                        <div className={isHovered ? "col-2" : "col-md-1 col-2"}>
                            <SideBar />
                        </div>
                        <div className={isHovered ? "col-10": "col-md-11 col-10 transtionApp"}>
                            {
                            // generalLoader ? <div className="d-flex align-items-center height-90">
                            //     <Loader active={true} inline="centered" />
                            //     </div> : 
                                <>
                                    {!location.pathname.includes("/chat-app") && <HeaderComponent />}
                                    <div className={!location.pathname.includes("/chat-app") ? "height-90" : "hv-100"}>
                                        {props.children}
                                        {allRequests.length > 0 && showRequestBadge &&
                                            <div className={`request_view ${t('local') !== "ar" ? 'right_request_view' : 'left_request_view'}`}>
                                                {t('shared.there')} <span>{allRequests.length}</span>{t('shared.traineesRequests')}
                                                <FontAwesomeIcon onClick={() => setShowRequestBadge(false)} icon={faTimesCircle}  className="request_icon"/>
                                            </div>
                                        }
                                    </div>
                                    {!location.pathname.includes("/chat-app") && <FooterComponent />}
                                </>
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }else{
        return null
    }

        // if(!this.state.isActive) {
        //     return <Redirect to={'/login?notActive=true'} />
        // }
       

            // <SideBarComponent>
            //     <HeaderComponent />
            //     {this.props.children}
            //     <div className="mt-4">
            //         <FooterComponent />
            //     </div>

            //     {

            //         this.state.hasVerticalScrollbar ?   <div className="scroll-bar">
            //             scroll
            //         </div>  : null
            //     }
            // </SideBarComponent>
}
export default withTranslation('translation') (App1);