// Profile Actions
export const Change_Profile_Avatar = "Change_Profile_Avatar";

export const Get_Requests = "Get_Requests"

// Templates
export const Set_All_Templates = "Set_All_Templates";
export const Set_Selected_Template = "Set_Selected_Template";
export const Set_Selected_Day = "Set_Selected_Day"
export const Set_Selected_Type = "Set_Selected_Type"
export const Get_Exercises_Meals_Per_Day = "Get_Exercises_Meals_Per_Day"
export const Set_Selected_Exercises = "Set_Selected_Exercises"
export const Set_Open_Days_Modal = "Set_Open_Days_Modal"
export const Open_Add_Exercise_modal = "Open_Add_Exercise_modal"
export const Open_Add_Exercise_Second_modal = "Open_Add_Exercise_Second_modal"
export const Fetch_Muscle_Exercises = "Fetch_Muscle_Exercises"
export const Show_Final_Modal = "Show_Final_Modal"
export const Set_Selected_Muscle_Exercise = "Set_Selected_Muscle_Exercise"
export const Set_Modal_Detailed = "Set_Modal_Detailed"
export const Set_Modal_Edited = "Set_Modal_Edited"
export const Set_Loaded = "Set_Loaded"
export const Set_Load = "Set_Load"
export const Clear = "Clear"
// meal
export const Open_Add_Meal_Modal = "Open_Add_Meal_Modal"
export const Open_Meal_Details = "Open_Meal_Details"
export const Set_Meal_Detail = "Set_Meal_Detail"
export const Open_Food_Modal = "Open_Food_Modal"
export const Show_Add_Food_Modal = "Show_Add_Food_Modal"
export const Set_Food_Item = "Set_Food_Item"
export const Open_Food_Modal_Edited = "Open_Food_Modal_Edited"

//// plans
export const Get_Exercises_Meals_Plan_Per_Day = "Get_Exercises_Meals_Plan_Per_Day"
export const Get_Plan_Data = "Get_Plan_Data"
export const Set_Loading = "Set_Loading"
export const Set_Subscription = "Set_Subscription"
export const Set_Selected = "Set_Selected"
export const Set_Subscription_Data = "Set_Subscription_Data"
export const Set_Calendar_Days = "Set_Calendar_Days"
export const Set_Active_Date = "Set_Active_Date"
export const Set_Calendar_Returned = "Set_Calendar_Returned"
export const Set_Active_index = "Set_Active_index"

export const Delete_Item = "Delete_Item"
export const Set_Open_Delete = "Set_Open_Delete"
export const Set_Open_Break = "Set_Open_Break"

export const Get_Trainees = "Get_Trainees"
export const Get_Fixed_Trainees = "Get_Fixed_Trainees"
export const Fetch_Fixed_Muscle_Exercises = "Fetch_Fixed_Muscle_Exercises"
export const Get_Invited_Trainees = "Get_Invited_Trainees"
export const Get_Fixed_Invited_Trainees = "Get_Fixed_Invited_Trainees"
export const Get_All_Templates = "Get_All_Templates"
export const Get_Fixed_Templates = "Get_Fixed_Templates"
export const Set_Profile = "Set_Profile"
export const Is_Temp_Loading = "Is_Temp_Loading"
export const Set_Is_Hovered = "Set_Is_Hovered"
export const Set_General_Loader = "Set_General_Loader"
export const Set_Logined = "Set_Logined"
export const Set_Chated = "Set_Chated"
export const Set_Token = "Set_Token"
export const Set_Toast_Msg = "Set_Toast_Msg"
export const Set_Msg_Found = 'Set_Msg_Found'