
/*
* General Configuration of Miran app, Specific Chat SDK.
*/

export default  {
    appId: "127312a7161b14d", //Enter your App ID
    apiKey: "4bfcdb8b5aef6f9b6df4f33ee1cf604ddeab1b67", //Enter your API KEY
    GUID: "", // Enter your group UID
    arabic : /[\u0600-\u06FF]/,
    english : /^[a-zA-Z]+$/,
    url:'https://backend.miranapp.com'
};

