import { 
    useEffect, 
    useState, 
    memo, 
    lazy, 
    Suspense 
} from 'react';
import {withTranslation} from "react-i18next";
import { useLocation } from 'react-router-dom'
import {  useDispatch, useSelector  } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch  } from '@fortawesome/free-solid-svg-icons'
import  './header.component.css';
import UserService from '../../../services/user-service/user.service';
import config from '../../../config'
const NotificationComponent = lazy(() => import('../notification/notification.component'))
const HeaderUserProfileComponent = lazy(() => import('../header-user-profile/header-user-profile.component'))
const ChangeLanguageComponent = lazy(() => import('../change-language/change-language.component'))
const list = [
    {
        chat_uid: "199602",
        email: "omar+t81145559@miranapp.com",
        full_name: "عمرو احمد",
        goal: {id: 1, title: 'Weight Loss', title_ar: 'إنقاص الوزن'},
        id: 199602,
        profile: {id: 155013, full_name: 'omar+t81145559', email: 'omar+t81145559@miranapp.com', birthdate: '1990-01-01'},
        subscribtion_end: 25
    },
    {
        chat_uid: "199608",
        email: "omar+t81145559@miranapp.com",
        full_name: "اخويا",
        goal: {id: 1, title: 'Weight Loss', title_ar: 'إنقاص الوزن'},
        id: 199608,
        profile: {id: 155013, full_name: 'omar+t81145559', email: 'omar+t81145559@miranapp.com', birthdate: '1990-01-01'},
        subscribtion_end: 25
    },
    {
        chat_uid: "199612",
        email: "omar+t81145559@miranapp.com",
        full_name: "مصطفي",
        goal: {id: 1, title: 'Weight Loss', title_ar: 'إنقاص الوزن'},
        id: 199612,
        profile: {id: 155013, full_name: 'omar+t81145559', email: 'omar+t81145559@miranapp.com', birthdate: '1990-01-01'},
        subscribtion_end: 25
    }
]


function HeaderComponent(props) {
    
    const [userName , setUserName] = useState("")
    const [searchValue , setSearchValue] = useState("")
    const [rtl , setRtl] = useState(true)
    const dispatch = useDispatch()
    const userService = new UserService()
    const { 
        trainees , 
        fixedTrainees , 
        invitedTrainees , 
        fixedInvitedTrainees ,
        templates,
        fixedTemplates
    } = useSelector(state => state.general_reducer)
    const { profileData } = useSelector(state => state.profile_reducer)
    const location = useLocation()

    useEffect(() => {
        // debugger
        if(Object.keys(profileData.profile).length > 0){
            // debugger
            setUserName(profileData.profile.full_name)
            dispatch({
                type: "Change_Profile_Avatar",
                avatar:`${config.url}/media/${profileData.profile.avatar}`
            })
        }
        // userService.profile.then(data => {
        //     if(data.result && data.result.profile){
        //     }
        //     // debugger;
        // })

    } , [profileData.profile])

    const handleSearch = e => {
        setSearchValue(e.target.value)
        if(e.target.value !== ""){
            if(config.arabic.test(e.target.value) === true){
                setRtl(true)
            }else{
                setRtl(false)
            }
            if(location.pathname === "/"){
                if(fixedTrainees.length > 0){
                        debugger
                        dispatch({
                            type:"Get_Trainees",
                            data: trainees.filter(item => item.full_name.includes(e.target.value))
                        })
                    }
            } else if(location.pathname.includes("/trainees")){
                debugger
                if(invitedTrainees.length > 0){
                    dispatch({
                        type:"Get_Invited_Trainees",
                        data: invitedTrainees.filter(item => item.trainee.email.includes(e.target.value))
                    })
                }
            } else if(location.pathname.includes("/template")){
                debugger
                if(templates.length > 0){
                    dispatch({
                        type:"Get_All_Templates",
                        data: templates.filter(item => item.name.includes(e.target.value))
                    })
                }
            }
        }else{
            setRtl(true)
            debugger
            if(location.pathname === "/"){
                debugger
                dispatch({
                    type:"Get_Trainees",
                    data: fixedTrainees
                })
            }else if(location.pathname.includes("/trainees")){
                debugger
                dispatch({
                    type:"Get_Invited_Trainees",
                    data: fixedInvitedTrainees
                })
            }else if(location.pathname.includes("/template")){
                debugger
                dispatch({
                    type:"Get_All_Templates",
                    data: fixedTemplates
                })
            }
        }
        console.log(list.filter(item => item.full_name.includes(e.target.value)))
        debugger
    }
    
        return (
          <div className="header-app container-fluid" >
              <div className="row align-items-center">
                  <div className="col-md-6 col-11">
                     <div className="row">

                         <div className="col-10">
                             <div className="form-group search-container">
                                 <input type="text"
                                        className={`form-control text-18 ${rtl ? 'text-end' : 'text-start ps-5 ltr-direction'}`}
                                        onChange={handleSearch}
                                        placeholder={props.t('header.searchTitle')}/>
                                 <div className="icon" style={{right:props.t('local') === 'ar'? 'auto' : '10px' , left:props.t('local') === 'ar' ? '10px' : 'auto'}}>
                                     <FontAwesomeIcon icon={faSearch} />
                                 </div>
                             </div>


                         </div>
                         <div className="col-2 d-flex justify-content-center">
                           {/* NOTIFICATION*/}
                           <Suspense fallback={null}>
                                <NotificationComponent />
                            </Suspense>
                         </div>

                     </div>
                  </div>
                  <div className="col-1 divider">
                      <div className="vertical-Separate">

                      </div>
                  </div>
                  <div className="col-md-3 col-6 mt-md-0 mt-2">
                        <Suspense fallback={null}>
                            <HeaderUserProfileComponent t={props.t} username={userName} t={props.t} />
                        </Suspense>
                  </div>
                  <div className="col-md-2 col-6 mt-3">
                        <Suspense fallback={null}>
                            <ChangeLanguageComponent />
                        </Suspense>
                  </div>
              </div>
          </div>
        );
}

export default withTranslation("translation")(memo(HeaderComponent));