import {
    useEffect,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {CometChat} from "@cometchat-pro/chat";

export const useCheckConversations = () => {

    const { trainees , logined } = useSelector(state => state.general_reducer)
    const dispatch = useDispatch()

    useEffect(() => {
        // debugger
        if(trainees.length > 0){
            const newTrainees = [...trainees]
            const updatedTrainees=[]
            if(logined){
                let conversationsRequest = new CometChat.ConversationsRequestBuilder()
                    .setLimit(30)
                    .build();
                conversationsRequest.fetchNext().then(
                conversationList => {
                    // debugger
                    console.log("local",  localStorage)
                    conversationList.forEach(e => {
                        // debugger
                        console.log(JSON.parse(localStorage.getItem("readConversations")))
                        if(e.unreadMessageCount > 0){
                            if(JSON.parse(localStorage.getItem("readConversations"))){
                                if(!JSON.parse(localStorage.getItem("readConversations")).find(lItem => lItem.conversationId === e.conversationId)){
                                    // debugger
                                        const updatedTraineeIndex = trainees.findIndex(item => item.chat_uid === e.conversationWith.uid)
                                        const updatedTrainee = trainees.find(item => item.chat_uid === e.conversationWith.uid)
                                        newTrainees[updatedTraineeIndex] = {...updatedTrainee , newMessageReceived:e.unreadMessageCount}
                                        // debugger
                                        if(updatedTrainee){
                                            // debugger
                                            dispatch({
                                                type:"Get_Trainees",
                                                data:newTrainees
                                            })
                                        }
                                    }else{
                                        // debugger
                                        const traineeIndex = trainees.findIndex(item => item.chat_uid === e.conversationWith.uid)
                                        const newTrainee = trainees.find(item => item.chat_uid === e.conversationWith.uid)
                                        newTrainees[traineeIndex] = {...newTrainee , newMessageReceived:undefined}
                                        dispatch({
                                            type:"Get_Trainees",
                                            data:newTrainees
                                        })
                                    }

                            }else{
                                // debugger
                                const traineeIndex = trainees.findIndex(item => item.chat_uid === e.conversationWith.uid)
                                const newTrainee = trainees.find(item => item.chat_uid === e.conversationWith.uid)
                                newTrainees[traineeIndex] = {...newTrainee , newMessageReceived:e.unreadMessageCount}
                                dispatch({
                                    type:"Get_Trainees",
                                    data:newTrainees
                                })
                            }
                        }else{
                            // debugger
                            const traineeIndex = trainees.findIndex(item => item.chat_uid === e.conversationWith.uid)
                            const newTrainee = trainees.find(item => item.chat_uid === e.conversationWith.uid)
                            newTrainees[traineeIndex] = {...newTrainee , newMessageReceived:undefined}
                            dispatch({
                                type:"Get_Trainees",
                                data:newTrainees
                            })
                        }
                    })
                    console.log("Conversations list received:", conversationList);
                }, error => {
                    console.log("Conversations list fetching failed with error:", error);
                }
                );
                dispatch({
                    type:"Set_Chated"
                })
            }
        }
    },[logined , trainees.length , localStorage])

}