import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import profile_reducer from './reducers/profile_reducer';
import general_reducer from './reducers/generalReducer'
import template_reducer from './reducers/templateReducer'
import plan_reducer from './reducers/planReducer'
     
const middlewares = [];

// Redux: Combine Multiple Reducers together.
const rootReducer = combineReducers({
    profile_reducer,
    general_reducer,
    template_reducer,
    plan_reducer
});
  
// Redux: Setup Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Redux: Setup the Store
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger)));
const store = composeEnhancers(applyMiddleware(...middlewares))(createStore)(rootReducer);

export default store;