import { memo, useEffect, useState } from 'react';
import {
    Grid,
    Image,
    Menu,
    Segment,
    Sidebar,
} from 'semantic-ui-react';
import {withTranslation} from "react-i18next";
import { CometChat } from '@cometchat-pro/chat';
import { withRouter } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import './sideBar.css';
import logo from '../../../assets/images/menu-logo-01.svg';
import homeIcon from '../../../assets/icons/home-01.svg';
import chatIcon from '../../../assets/icons/chat.svg';
import profileIcon from '../../../assets/icons/profile-01.svg';
import templateIcon from '../../../assets/icons/template.svg';
import settingIcon from '../../../assets/icons/settings.svg';
import logoutIcon from '../../../assets/icons/logout-01.svg';
import fitnessIco from '../../../assets/icons/fitness-01.svg';
import AccountService from "../../../services/account-service/account.service";
import {ChatService} from "../../../pages/group-chat/service/chat.service";
import { useDispatch, useSelector } from 'react-redux';

const SideBar = props => {

    const [isSas , setIsSas] = useState(false)
    const [clicked, setClicked] = useState(1)
    const accountService = new AccountService();
    const chatService  = new ChatService();
    const { isHovered } = useSelector(state => state.general_reducer)
    const dispatch = useDispatch()
    const { t } = props;
    const isArabic = props.i18n.language === 'ar';
    const direction = isArabic ? 'right':'left';


    useEffect(() => {
        setIsSas(accountService.Account_Type === 'SAS')
    }, [accountService.Account_Type])
    
    const logoutHandler = async () => {
        dispatch({
            type:"Get_Trainees",
            data: []
        })
        dispatch({
            type: "Get_All_Templates",
            data:[]
        })
        dispatch({
            type: "Get_Invited_Trainees",
            data:[]
        })
        debugger
        const loginedUser = await CometChat.getLoggedinUser()
        debugger
        if(loginedUser !== null){
            await CometChat.logout().then(() => {
                localStorage.clear();
                props.history.push('/login')
                console.log("logout success")
                debugger
            })
        }
    }
    const handleClicked = n => {
        setClicked(n)
        switch (n) {
            case 1 :
                return props.history.push("/")
            case 2 : 
                return props.history.push({
                            pathname: '/chat-app',
                            state: {chats:t("shared.Chats")}
                        });
            case 3 : 
                return props.history.push("/trainees")
            case 4 : 
                return props.history.push("/profile")
            case 5 : 
                return props.history.push("/template")
            case 6 : 
                return props.history.push("/setting")
        }
    }

    const showHover = () => {
        dispatch({
            type:"Set_Is_Hovered",
            hovered:true
        })
    }

    const hideHover = () => {
        dispatch({
            type:"Set_Is_Hovered",
            hovered:false,
        })
        // if(props.location.pathname === "/template"){
        //     dispatch({
        //         type:"Set_General_Loader",
        //         loading:true,
        //     })
    
        //     setTimeout(() => {
        //         dispatch({
        //             type:"Set_General_Loader",
        //             loading:false,
        //         })
        //     } , 100)
        // }
    }


    return (
        <div className={`pos-fixed ${isHovered ? 'active-pos-fixed' : ''}`} onMouseLeave={hideHover}>
            <div className="sideBar" onMouseLeave={hideHover}>
                <Image src={logo} onClick={() => props.history.push("/")}  className={isHovered ? "activeSideImage" : "sideImage"} />
                <div className="mt-3">
                    <div onMouseEnter={showHover} className={`sideBarItem ${clicked === 1 ? "activeSideBarItem" : ""}`} onClick={() => handleClicked(1)}>
                        <Image src={homeIcon} className='icon' width={20}  />
                        {isHovered && <h4 className={t('local') === "ar" ? "me-4" : "ms-4"}>{t('menu.dashboard')}</h4>}
                    </div>
                    <div onMouseEnter={showHover} className={`sideBarItem ${clicked === 2 ? "activeSideBarItem": ""}`} onClick={() => handleClicked(2)}>
                        <Image src={chatIcon} className='icon' width={20} />
                        {isHovered && <h4 className={isArabic ? "me-4" : "ms-4"}>{t('menu.chats')}</h4>}
                    </div>
                    {
                        isSas && <div onMouseEnter={showHover} className={`sideBarItem ${clicked === 3 ? "activeSideBarItem": ""}`} onClick={() => handleClicked(3)}>
                            <Image src={fitnessIco} className='icon' width={20} />
                            {isHovered && <h4 className={t('local') === "ar" ? "me-4" : "ms-4"}>{t('menu.trainees')}</h4> }
                        </div>
                    }
                    <div onMouseEnter={showHover} className={`sideBarItem ${clicked === 4 ? "activeSideBarItem": ""}`} onClick={() => handleClicked(4)} >
                        <Image src={profileIcon} className='icon' width={20} />
                        {isHovered && <h4 className={t('local') === "ar" ? "me-4" : "ms-4"}>{t('menu.profile')}</h4>}
                    </div>
                    <div onMouseEnter={showHover} className={`sideBarItem ${clicked === 5 ? "activeSideBarItem": ""}`} onClick={() => handleClicked(5)}>
                        <Image src={templateIcon} className='icon' width={20} />
                        {isHovered && <h4 className={t('local') === "ar" ? "me-4" : "ms-4"}>{t('menu.templates')}</h4>}
                    </div>
                    <div onMouseEnter={showHover} className={`sideBarItem ${clicked === 6 ? "activeSideBarItem" : ""}`} onClick={() => handleClicked(6)}>
                        <Image src={settingIcon} className='icon' width={20} />
                        {isHovered && <h4 className={t('local') === "ar" ? "me-4" : "ms-4"}>{t('menu.settings')}</h4>}
                    </div>
                    <div onMouseEnter={showHover} className="d-flex align-items-center text-white p-4 mt-4 cursor-pointer" onClick={logoutHandler}>
                        <Image src={logoutIcon} className='icon logout' width={20} />
                        {isHovered && <h4  className={t('local') === "ar" ? "me-4 text-16" : "ms-4 text-18"}>{t('menu.logout')}</h4>}
                    </div>
                </div>
            </div>
        </div>
        // <Grid>
        //     <Grid.Column width={16} >
        //         <Sidebar.Pushable as={Segment}>
        //             <Sidebar
        //                 as={Menu}
        //                 animation={'slide along'}
        //                 direction={direction}
        //                 icon='labeled'
        //                 inverted
        //                 vertical
        //                 visible={true}
        //                 width='wide'
        //             >
        //                 <Menu.Header >
        //                     <Image src={logo} width={150} style={{margin:'25px auto'}}/>
        //                 </Menu.Header>
        //                 <Menu.Item  as='a'
        //                             className={this.state.active === '/' ? 'active' : {}}
        //                             onClick={e => {
        //                     this.props.history.push('/');
        //                     this.setState({ active: '/' });
        //                 }}>
        //                     <Image src={homeIcon} className='icon' width={25} />
        //                     {t('menu.dashboard')}
        //                 </Menu.Item>
        //                 <Menu.Item as='a'
        //                            className={this.state.active === 'chats' ? 'active' : {}}
        //                            onClick={e => {
        //                                this.props.history.push('/chat-app');
        //                                this.setState({ active: 'chats' });
        //                            }}>
        //                       <Image src={chatIcon} className='icon' width={25} />
        //                     {t('menu.chats')}
        //                 </Menu.Item>
        //                 {
        //                     this.state.isSas ?
        //                         <>
        //                             <Menu.Item as='a'
        //                                        className={this.state.active === 'trainees' ? 'active' : {}}
        //                                        onClick={e => {
        //                                            this.props.history.push('/trainees')
        //                                            this.setState({ active: 'trainees' });
        //                                        }}
        //                             >
        //                                 <Image src={fitnessIco} className='icon' width={25} />
        //                                 {t('menu.trainees')}
        //                             </Menu.Item>
        //                         </>: null
        //                 }

        //                 <Menu.Item as='a'
        //                            className={this.state.active === 'profile' ? 'active' : {}}
        //                            onClick={e => {
        //                     this.props.history.push('/profile');
        //                     this.setState({ active: 'profile' });
        //                 }}>
        //                     <Image src={profileIcon} className='icon' width={25} />
        //                     {t('menu.profile')}
        //                 </Menu.Item>
        //                 <Menu.Item as='a'
        //                            className={this.state.active === 'template' ? 'active' : {}}
        //                            onClick={e => {
        //                                this.props.history.push('/template');
        //                                this.setState({ active: 'template' });
        //                            }}
        //                 >
        //                     <Image src={templateIcon} className='icon' width={25} />
        //                     {t('menu.templates')}
        //                 </Menu.Item>
        //                 <Menu.Item as='a'
        //                            className={this.state.active === 'setting' ? 'active' : {}}
        //                            onClick={e => {
        //                     this.props.history.push('/setting');
        //                     this.setState({ active: 'setting' });
        //                 }}
        //                 >
        //                     <Image src={settingIcon} className='icon' width={25} />
        //                     {t('menu.settings')}
        //                 </Menu.Item>
        //                 <Menu.Item as='a' onClick={(e) => {
        //                     e.preventDefault();
        //                     this.logoutHandler();
        //                 }}>
        //                     <Image src={logoutIcon} className='icon logout' width={25} />
        //                     {t('menu.logout')}
        //                 </Menu.Item>
        //             </Sidebar>
        //             <Sidebar.Pusher>
        //                 {this.props.children}
        //             </Sidebar.Pusher>
        //         </Sidebar.Pushable>
        //     </Grid.Column>
        // </Grid>


    );
}

export default withTranslation("translation")(withRouter((memo(SideBar))));